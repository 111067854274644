<template>
  <v-row justify="start">
    <v-dialog v-model="dialogComment" scrollable persistent max-width="400px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">Edit Comment</span>
          <v-spacer></v-spacer>
          <v-btn fab x-small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="px-2" v-if="dataComment">
          <v-container>
            <v-textarea
              solo
              hide-details
              v-model="dataComment.content"
            ></v-textarea>
          </v-container>
        </v-card-text>
        <v-overlay :value="loading" :absolute="true" color="white">
          <v-progress-circular
            color="grey"
            indeterminate
            size="35"
          ></v-progress-circular>
        </v-overlay>
        <notifications group="validate" class="mt-2" position="top center" />
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" outlined rounded @click="updateData">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "editComent",

  props: ["dialogComment", "dataComment"],

  data() {
    return {
      loading: false
    };
  },

  methods: {
    updateData() {
      let datas = {
        content: this.dataComment.content,
        id: this.dataComment.id
      };
      this.loading = true;
      this.$store
        .dispatch("library/updateCommentArticle", datas)
        .then(data => {
          console.log(data);
          this.loading = false;
          this.$emit("refetch");
          this.$emit("close");
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            showConfirmButton: false,
            position: "top-end",
            toast: true,
            timer: 3000
          });
        })
        .catch(err => {
          this.alert("error", `${err.message}`);

          this.loading = false;
        });
    },

    alert(type, title) {
      this.$notify({
        group: "validate",
        type: type,
        title: title,
        duration: 5000
      });
    }
  }
};
</script>

<style scoped>
.add-width {
  width: 170px;
}
.temp {
  position: relative;
}
.over {
  position: absolute;
  margin-top: -5px;
  margin-right: -5px;
}
.img-temp__ {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px black dotted;
}
.bg {
  background: #ffff;
}
</style>
